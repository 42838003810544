import "./assets/css/main.css"
import "./assets/css/noscript.css"
import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import ProjectService from "../ProjectService/ProjectService";
import { Image } from 'primereact/image';
import { Chip } from 'primereact/chip'
// import imMain from "../../../public/images/post0/image3.png"
// import im1 from "../../../public/images/post0/image3.png"
// import im2 from "../../../public/images/post0/image1.png"
// import im3 from "../../../public/images/post0/image4.png"
// import im4 from "../../../public/images/post0/image5.png"
// import im5 from "../../../public/images/post0/image6.png"
// import im6 from "../../../public/images/post0/image7.png"
// import im7 from "../../../public/images/post0/image2.png"
// import im8 from "../../../public/images/post0/image8.png"
import TechList from "../TechList/TechList";
import TechChipList from "../TechList/TechList"
import {Helmet} from "react-helmet-async";


const BtloChallenge = (data) => {
    let { id } = useParams();
    const projectService = new ProjectService();
    let project = projectService.getProject(id);
    useEffect(() => {
        window.scrollTo(0, 150);
    }, []);
    return (
        <div id="wrapper">
            <Helmet>
                <title>Alvaro de Castro - {project.name} </title>
                <meta
                    name="description"
                    content={project.meta_desc}
                />
            </Helmet>
            <div id="main">
                <section className="post">
                    <header className="major">
                        <span className="date">{project.date}</span>
                        <h1>{project.name}</h1>
                        <p>{project.description}</p>
                        <TechList list={project.technologies} className={"chipList"} chip={true} />
                    </header>
                    {/*<div className="image main">*/}
                    {/*    <Image src={imMain} alt="" />*/}
                    {/*</div>*/}
                    <div
                        dangerouslySetInnerHTML={{__html: project.html_content}}
                    />

                </section>

            </div>
        </div>

    );
};
export default BtloChallenge;